var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vx-input', _vm._g(_vm._b({
    attrs: {
      "type": "autocomplete",
      "name": "tags",
      "label": "Tags",
      "multiple": "",
      "items": _vm.tags,
      "item-text": function itemText(item) {
        return "".concat(_vm.types[item.type], " - ").concat(item.name);
      },
      "item-value": "slug",
      "return-object": true,
      "append-outer-icon": "mdi-plus"
    },
    on: {
      "click:append-outer": function clickAppendOuter($event) {
        _vm.$openBlankWindow(_vm.$toAdd('tag'));
      }
    }
  }, 'vx-input', _vm.$attrs, false), _vm.$listeners));
}
var staticRenderFns = []

export { render, staticRenderFns }