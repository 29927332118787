<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-8">
        <v-row>
          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="file.name" type="text" name="name" label="Name" required />
          </v-col>

          <v-col cols="12" sm="6" class="py-0">
            <vx-input v-model="file.url" type="text" name="url" label="URL" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12" class="py-0">
                <tags v-model="file.tags" />
              </v-col>
              <v-col cols="12" class="py-0">
                <vx-input v-model="file.alt" type="text" name="alt" label="Alt Tags" />
              </v-col>

              <v-col cols="12" class="py-0">
                <vx-input
                  v-model="file.description"
                  type="textarea"
                  name="description"
                  label="Description"
                  counter="280"
                  max="280"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="6">
            <v-row>
              <v-col cols="12">
                <vx-img class="rounded border-primary-1" rectangle :src="file.url" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col cols="12" sm="6">
            <FileUploader />
          </v-col>
        </v-row> -->
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import { FileService } from '@tutti/services';
import { File } from '@tutti/models';
// import FileUploader from '@tutti/components/FileUploader';
import Tags from '@admin/components/tags';

export default {
  name: 'LandingPageEdit',

  components: {
    // FileUploader,
    Tags,
  },

  data() {
    return {
      loading: false,
      file: new File(),
    };
  },

  async created() {
    this.tab = this.$route?.params?.tab || 0;

    await this.getFile();
  },

  methods: {
    async getFile() {
      if (this.$route.params.id) {
        const response = await FileService.getById(this.$route.params.id);

        if (response) {
          this.file = new File(response.data);
          this.$setTitle(this.file.name);
        }
      }
    },

    async save(previous) {
      this.loading = true;
      this.file.setAltTagToUrl();
      const response = await FileService.save(this.file.get());
      if (response) {
        if (previous) {
          this.$previous();
        } else if (response.data._id) {
          this.$replace(this.$toEdit('file', response.data._id));
        }
      }
      this.loading = false;
    },
  },
};
</script>
