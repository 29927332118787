<template>
  <vx-input
    type="autocomplete"
    v-bind="$attrs"
    name="tags"
    label="Tags"
    multiple
    :items="tags"
    :item-text="item => `${types[item.type]} - ${item.name}`"
    item-value="slug"
    :return-object="true"
    append-outer-icon="mdi-plus"
    v-on="$listeners"
    @click:append-outer="$openBlankWindow($toAdd('tag'))"
  />
</template>

<script>
import { TagService } from '@tutti/services';
import { TAG_TYPES } from '@tutti/constants';

export default {
  name: 'Tags',

  data() {
    return {
      tags: [],
      types: this.$reduce(TAG_TYPES, 'value', 'text'),
    };
  },

  created() {
    this.getTags();
  },

  methods: {
    async getTags() {
      const response = await TagService.getAll({ option: { wantPagination: false } });

      if (response) {
        this.tags = response.data.data;
      }
    },
  },
};
</script>
